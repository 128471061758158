import { ForwardedRef, forwardRef } from "react";
import RoundInput, { RoundInputProps } from "./RoundInput";

const BorderColoredInput = ({ sx, ...inputProps }: RoundInputProps, ref?: ForwardedRef<HTMLInputElement>) => {
    return (
        <RoundInput
            ref={ref}
            sx={{
                border: "1px solid var(--color-neutral-black)",
                "&:focus": { borderColor: "#50d2c2" },
                fontFamily: "var(--knowt-font-name)",
                backgroundColor: "transparent",
                ...sx,
            }}
            {...inputProps}
        />
    );
};

export default forwardRef<HTMLInputElement, RoundInputProps>(BorderColoredInput);
