import { FlexColumn } from "@/components/Flex";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";

const StatsCards = () => {
    return (
        <>
            {[
                { stat: "3M +", desc: "Student & teacher users" },
                { stat: "5M +", desc: "Study notes created " },
                { stat: "10M + ", desc: "Flashcards sets created" },
            ].map(stat => (
                <FlexColumn
                    key={stat.stat}
                    style={{
                        textAlign: "center",
                        width: "100%",
                        borderRadius: borderRadius.card,
                        padding: "3rem",
                        gap: spacing.XS,
                        backgroundColor: themeColors.neutralWhite,
                    }}>
                    <span style={{ fontSize: "44px" }}>{stat.stat}</span>
                    <span className="secondaryText1">{stat.desc}</span>
                </FlexColumn>
            ))}
        </>
    );
};

export default StatsCards;
