import { themeColors } from "@/utils/themeColors";
import { ConditionalLink } from "../ConditionalLink";
import { FlexColumn, FlexRowAlignCenter } from "../Flex";
import CircularRectTextButton from "../CircularButton/styled/CircularRectTextButton";
import { spacing } from "@/utils/spacing";
import { borderRadius } from "@/utils/borderRadius";
import { ArrowRight } from "lucide-react";

const LinkCard = ({
    title,
    subtitle,
    href,
    headerComponent,
    bottomComponent,
    headerComponentStyle,
    arrowIconProps = {
        size: 25,
        color: themeColors.pureWhite,
        style: { transform: "rotate(-45deg)" },
    },
    textColor,
    style,
}: {
    title?: string;
    subtitle?: string;
    headerComponent?: React.ReactNode;
    href?: string;
    bottomComponent: React.ReactNode;
    headerComponentStyle?: React.CSSProperties;
    arrowIconProps?: React.ComponentProps<typeof ArrowRight>;
    style?: React.CSSProperties;
    textColor?: string;
}) => {
    return (
        <FlexColumn
            style={{
                borderRadius: "3rem",
                backgroundColor: themeColors.videoLight,
                height: "33rem",
                width: "29rem",
                flexShrink: 0,
                color: textColor ?? themeColors.neutralBlack,
                ...style,
            }}>
            <FlexRowAlignCenter
                style={{
                    justifyContent: "space-between",
                    gap: "4rem",
                    padding: "2.1rem 2.1rem 0",
                    ...headerComponentStyle,
                }}>
                {headerComponent || (
                    <FlexColumn style={{ textAlign: "left" }}>
                        <span style={{ fontSize: "2.5rem", fontWeight: "700" }}>{title}</span>
                        {subtitle && <span className="secondaryText1">{subtitle}</span>}
                    </FlexColumn>
                )}
                <ConditionalLink condition={!!href} href={href}>
                    <CircularRectTextButton
                        component={"div"}
                        disabled
                        style={{
                            backgroundColor: themeColors.pureBlack,
                            padding: spacing.XS,
                            borderRadius: borderRadius.circular,
                            cursor: href ? "pointer" : "default",
                        }}>
                        <ArrowRight {...arrowIconProps} />
                    </CircularRectTextButton>
                </ConditionalLink>
            </FlexRowAlignCenter>
            {bottomComponent}
        </FlexColumn>
    );
};

export default LinkCard;
