"use client";

import KnowtMUIThemeProvider from "@/components/KnowtMUIThemeProvider";
import { useMounted } from "@/hooks/useMounted";
import { themeColors } from "@/utils/themeColors";
import MUITab from "@mui/material/Tab";
import MUITabs, { TabsProps as MUITabsProps } from "@mui/material/Tabs";
import React from "react";
import CircularRectTextButton from "../CircularButton/styled/CircularRectTextButton";
import { childrenAsKey } from "@/utils/childrenAsKey";

export type TabOption<T = Record<string, unknown>> = {
    id?: string;
    label: string | React.ReactNode;
    value: string | number | boolean;
    tooltip?: string;
} & Partial<T>;

export type TabsProps<T extends TabOption> = {
    id?: string;
    tabs: T[];
    selectedTab: number;
    onChange: (tab: TabOption<T>, i: number) => void;
    tabsSx?: MUITabsProps["sx"];
    tabSx?: MUITabsProps["sx"];
    containerStyle?: React.CSSProperties;
};

/**
 * Wrapper for MUITabs which work with our UI 
 * How to modify:
 * - Change font size: `tabSx={{ fontSize: "1.6rem" }}`
 * - Change height: `tabsSx={{ height: "5.5rem" }}`
 * - Change tab color:
 *```typescript
  tabsSx={{
      // modify the selected tab color
      "& .Mui-selected": { color: themeColors.neutralBlack },
      // modify the selected tab pill indicator bg color
      "& .MuiTabs-indicator": { backgroundColor: themeColors.neutralWhite },
  }}
 *```
 * - Change hover tab background color:
 *```typescript
  tabSx={{
      // modify the hovered tab bg color
      ":not(.Mui-selected):hover": {
          backgroundColor: themeColors.neutral2
      }
  }}
 *```
 */
const Tabs = <T extends TabOption>({
    id,
    tabsSx,
    tabSx,
    selectedTab,
    tabs,
    onChange,
    containerStyle,
}: TabsProps<T>) => {
    // for now nextjs and MUI 6 seems to have this hydration error. until it got fixed, this is the temp solution
    const mounted = useMounted();

    // biome-ignore lint/suspicious/useValidTypeof: I do want to compare null/undefined with `==`
    if (typeof window == null || !mounted) {
        return <div style={{ height: containerStyle?.height || tabsSx?.height || "5.5rem" }} />;
    }

    return (
        <KnowtMUIThemeProvider>
            <div style={{ width: "fit-content", ...containerStyle }} id={id}>
                <MUITabs
                    textColor={"inherit"}
                    value={selectedTab}
                    onChange={(_e, index) => {
                        onChange(tabs[index], index);
                    }}
                    sx={[
                        {
                            minHeight: "0",
                            height: "5.5rem",
                            backgroundColor: themeColors.neutralWhite,
                            borderRadius: "999px",
                            // MUITab's parent/wrapper
                            "& .MuiTabs-flexContainer": {
                                position: "relative",
                                padding: "0 0.4rem",
                                zIndex: 1,
                                height: "100%",
                            },
                            // current selected tab text color
                            "& .Mui-selected": { color: themeColors.neutralWhite },
                            // current selected tab's pill indicator
                            "& .MuiTabs-indicator": {
                                minHeight: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                height: "80%",
                                borderRadius: "999px",
                                backgroundColor: themeColors.neutralBlack,
                                transition: "none",
                            },
                        },
                        ...(Array.isArray(tabsSx) ? tabsSx : [tabsSx]),
                    ]}>
                    {tabs.map((tab, i) => (
                        <MUITab
                            id={tab.id}
                            component={CircularRectTextButton}
                            disableFocusRipple
                            key={childrenAsKey({ children: tab.label, seed: i })}
                            disableRipple
                            label={tab.label}
                            tooltip={tab.tooltip}
                            sx={[
                                {
                                    minWidth: 0,
                                    "&:hover": { boxShadow: "unset", outline: "none" },
                                    "&:active": { boxShadow: "unset" },
                                    height: "100%",
                                    fontWeight: "bold",
                                    fontSize: "1.6rem",
                                    fontFamily: "var(--knowt-font-name)",
                                    color: themeColors.neutralBlack,
                                    minHeight: 0,
                                    transition: "color 0.5s ease",
                                    textTransform: "initial",
                                    marginBlock: "auto",
                                    marginInline: "0.4rem",
                                    "&:first-of-type": { marginLeft: "0px" },
                                    "&:last-of-type": { marginRight: "0px" },
                                    opacity: 1,
                                    ":not(.Mui-selected):hover": {
                                        height: "80%",
                                        backgroundColor: themeColors.neutral1,
                                        borderRadius: 999,
                                        outline: `1px solid ${themeColors.neutralBlack}`,
                                    },
                                },
                                ...(Array.isArray(tabSx) ? tabSx : [tabSx]),
                            ]}
                        />
                    ))}
                </MUITabs>
            </div>
        </KnowtMUIThemeProvider>
    );
};

export default Tabs;
