import { FlexColumn } from "@/components/Flex";
import UserContentCard from "@/components/cards/UserContentCard";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { spacing } from "@/utils/spacing";
import { isNote } from "@knowt/syncing/utils/dataCleaning";
import { isDevelopment } from "@/config/deployConstants";

type ExplopreTopUserContentsProps =
    | {
          notes: Note[];
          flashcardSets?: never;
          title?: string;
          type?: string;
          containerStyle?: React.CSSProperties;
          itemMinWidth?: `${number}rem`;
      }
    | {
          notes?: never;
          flashcardSets: FlashcardSet[];
          title?: string;
          type?: string;
          containerStyle?: React.CSSProperties;
          itemMinWidth?: `${number}rem`;
      };

const ExploreTopUserContents = ({
    notes,
    flashcardSets,
    title,
    type,
    itemMinWidth,
    containerStyle,
}: ExplopreTopUserContentsProps) => {
    const items = notes ?? flashcardSets;

    if (!items.length) {
        return (
            <FlexColumn style={{ gap: spacing.SM, width: "100%", ...containerStyle }}>
                <h2 className="bodyBoldXl">{title ?? `Explore top ${type ?? ""} ${notes ? "notes" : "flashcards"}`}</h2>
                <p className="bodyLg">
                    {isDevelopment
                        ? "No items found, we will populate this on prod."
                        : "No items found, please try again later."}
                </p>
            </FlexColumn>
        );
    }

    return (
        <FlexColumn style={{ gap: spacing.SM, width: "100%", ...containerStyle }}>
            <h2 className="bodyBoldXl">{title ?? `Explore top ${type ?? ""} ${notes ? "notes" : "flashcards"}`}</h2>
            <RepeatAutoFillGrid itemMinWidth={itemMinWidth ?? "24rem"} style={{ gap: "3rem" }}>
                {items.map((item: Note | FlashcardSet, i) => (
                    <UserContentCard
                        key={isNote(item) ? item.noteId : item.flashcardSetId + i}
                        {...(isNote(item) ? { note: item } : { flashcardSet: item })}
                    />
                ))}
            </RepeatAutoFillGrid>
        </FlexColumn>
    );
};

export default ExploreTopUserContents;
