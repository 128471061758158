import { ASSETS_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";
import { BookCheck, BrainCircuit, CircleDollarSign, Library, MessageCircle, Settings2, TextSearch } from "lucide-react";

const KaiGameShow = `${ASSETS_URL}/images/kai-game-show.svg`;
const FlashcardIcon = `${ASSETS_URL}/images/flashcard-icon.svg`;
const ExamIcon = `${ASSETS_URL}/images/ExamIcon.png`;
const YoutubeIcon = `${ASSETS_URL}/images/logo_youtube.png`;
const CanvasIcon = `${ASSETS_URL}/images/logo_canvas.png`;
const ZoomIcon = `${ASSETS_URL}/images/logo_zoom.png`;
const PanoptaIcon = `${ASSETS_URL}/images/logo_panopta.png`;
const YujaIcon = `${ASSETS_URL}/images/logo_yuja.png`;
const pdfIcon = `${ASSETS_URL}/images/pdf-icon.svg`;
const BrainIcon = `${ASSETS_URL}/images/brain.svg`;
const QuizletIcon = `${ASSETS_URL}/images/quizlet-icon.svg`;
const KnowtLogo = `${ASSETS_URL}/images/knowt-logo-minimal.svg`;
const ImportArrowIcon = `${ASSETS_URL}/images/import-arrow.svg`;
const PowerPointIcon = `${ASSETS_URL}/images/power-point-rounded.svg`;
const GoogleSlidesIcon = `${ASSETS_URL}/images/google-slides.svg`;
const ExcelIcon = `${ASSETS_URL}/images/excel-icon.svg`;
const GoogleSheetsIcon = `${ASSETS_URL}/images/google-sheets-icon.svg`;
const CrownIcon = `${ASSETS_URL}/images/crown.svg`;
const SwordIcon = `${ASSETS_URL}/images/sword.svg`;

export const LANDING_LINKS = {
    AI_VIDEO_SUMMARIZER: "/ai-video-summarizer",
    AI_LECTURE_NOTE_TAKER: "/ai-lecture-note-taker",
    AI_PDF_SUMMARIZER: "/ai-pdf-summarizer",
    AI_POWERPOINT_SUMMARIZER: "/ai-powerpoint-summarizer",
    AI_SPREADSHEET_SUMMARIZER: "/ai-spreadsheet-summarizer",
    AI_NOTES: "/ai-notes",
    AI_FLASHCARDS: "/",
    MOBILE: "/mobile",
    LEARN_MODE: "/learn-mode",
    CHROME_EXTENSION:
        "https://chromewebstore.google.com/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm?hl=en",
    EXAM_PRACTICE_ROOM: "/exams/AP/practice-test-room",
    FREE_RESPONSE_PRACTICE_ROOM: "/exams/AP/frq-room",
    PLANS: "/plans",
};

export enum UploadLandingPageTopicEnum {
    AIFlashcards = "AI Flashcards",
    School = "School",
    Fiveable = "Fiveable",
    FlashcardMaker = "Flashcard Maker",
}

export enum AIGenerationLandingPageTopic {
    VideoSummarizer = "Video Summarizer",
    LectureNoteTaker = "Lecture Note Taker",
    PDFSummarizer = "PDF Summarizer",
    PPTSummarizer = "PPT Summarizer",
    SpreadSheetSummarizer = "Spreadsheet Summarizer",
    AINotes = "AI Notes",
}

export const UploadLandingPageTopic = {
    ...UploadLandingPageTopicEnum,
    ...AIGenerationLandingPageTopic,
};

type LinkedCard = {
    images: string[];
    alt: string;
    imagesWidth: number;
    imagesHeight: number;
    title: string;
    description: string;
    link?: string;
    linkText: string;
    shortLinkText: string;
};

type VideoWithTwoLinkedCard = {
    mainTitle?: string;
    videoComponent: {
        title: string;
        subtitle: string;
        linkText?: string;
        shortLinkText?: string;
        link?: string;
        videoId: string;
    };
    linkedCards: LinkedCard[];
};

const learnWithTextingContent: VideoWithTwoLinkedCard = {
    mainTitle: "We’ll watch the video & make you a summary on the important stuff.",
    videoComponent: {
        title: "Learn by texting?",
        subtitle: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
        videoId: "899614007",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Quiz yourself on those long videos",
            description: "Kai will quiz you! Studies show that’s a lot more effective than watching.",
            linkText: "Upload a video now",
            shortLinkText: "Upload video",
        },
        {
            images: [YoutubeIcon, CanvasIcon, ZoomIcon, PanoptaIcon, YujaIcon],
            alt: "press to download chrome extension",
            imagesWidth: 102,
            imagesHeight: 102,
            title: "Works for Youtube & other sites",
            description: "Use our chrome extension to upload from the most popular sites",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const lectureNoteTakerContent: VideoWithTwoLinkedCard = {
    mainTitle: "We’ll record your lecture & take notes for you. You can focus on the listening.",
    videoComponent: {
        title: "Go from your lecture to notes in seconds.",
        subtitle: "After class, you can shoot Kai a text & he’ll answer your questions just like your teacher would. ",
        // FIXME:  videoId might not be correct, get it from Ramya or Abheek
        videoId: "899614007",
    },
    linkedCards: [
        {
            // FIXME:  images might not be correct, get it from Ramya or Abheek
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Quiz yourself on the lecture topics.",
            description: "Kai will listen to your lecture live & make you quizzes to take after.",
            linkText: "Start recording your lecture",
            shortLinkText: "Start recording",
        },
        {
            images: [YoutubeIcon, CanvasIcon, ZoomIcon, PanoptaIcon, YujaIcon],
            alt: "press to download chrome extension",
            imagesWidth: 102,
            imagesHeight: 102,
            title: "Live Lecture Note Taker",
            description: "Hit record to capture your lecture in real-time & get an outline.",
            link: LANDING_LINKS.AI_LECTURE_NOTE_TAKER,
            linkText: "Make notes from your lecture",
            shortLinkText: "Make notes",
        },
    ],
};

const pdfSummarizerToHelpContent: VideoWithTwoLinkedCard = {
    mainTitle: "Get Started for Free. Read PDFs faster with our AI PDF Summarizer",
    videoComponent: {
        title: "The PDF Summarizer built to help you learn.",
        subtitle: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
        videoId: "899614017",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Quiz yourself on PDF documents",
            description: "Kai will quiz you! Studies show that’s a lot more effective than reading.",
            linkText: "Upload a PDF now",
            shortLinkText: "Upload video",
        },
        {
            images: [pdfIcon],
            alt: "press to download chrome extension",
            imagesWidth: 107,
            imagesHeight: 140,
            title: "Analyze & Summarize PDFs from any site",
            description: "Use our chrome extension to upload from the most popular sites.",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const spreadsheetSummarizerToHelpContent: VideoWithTwoLinkedCard = {
    mainTitle: "Understand Spreadsheets faster with our AI-powered Spreadsheet Summarizing Tool",
    videoComponent: {
        title: "The Spreadsheet Summarizer built to help you learn.",
        subtitle:
            "It’s as simple as sending a text to Kai, and he’ll explain anything in your PDF to you like a tutor would.",
        videoId: "899614017",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload spreadsheet",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Take practice tests generated from your Spreadsheets",
            description: "Kai will read through your spreadsheet and create a test that highlights the key concepts.",
            linkText: "Upload a spreadsheet now",
            shortLinkText: "Upload spreadsheet",
        },
        {
            images: [ExcelIcon, GoogleSheetsIcon],
            alt: "press to download chrome extension",
            imagesWidth: 107,
            imagesHeight: 140,
            title: "Upload Spreadsheets from any site in seconds",
            description: "Use our Chrome extension to upload a Spreadsheet from any webpage to Knowt in seconds.",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const pptSummarizerToHelpContent: VideoWithTwoLinkedCard = {
    mainTitle: "Learn your powerpoints faster with our AI-powered PPT Summarizing Tool",
    videoComponent: {
        title: "The PPT Summarizer built to help you learn.",
        subtitle:
            "It’s as simple as sending a text to Kai, and he’ll explain anything in your PPT to you like a tutor would.",
        videoId: "899614017",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Take practice tests generated from your PPT",
            description: "Kai will read through your PPT and create a test that highlights the key concepts.",
            linkText: "Upload a PPT now",
            shortLinkText: "Upload video",
        },
        {
            images: [GoogleSlidesIcon, PowerPointIcon],
            alt: "press to download chrome extension",
            imagesWidth: 107,
            imagesHeight: 100,
            title: "Upload PPTs from any site in seconds",
            description: "Use our Chrome extension to upload a PPT from any webpage to Knowt in seconds.",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const aiNotesContent: VideoWithTwoLinkedCard = {
    mainTitle: "Turn your Notes into Flashcards with our AI-Powered Notes",
    videoComponent: {
        title: "The AI Flashcard Maker built to help you learn.",
        subtitle: "It’s as simple as pasting in your text and pressing a single button",
        videoId: "899614079",
    },
    linkedCards: [
        {
            images: [FlashcardIcon],
            alt: "turn your notes into flashcards instantly",
            imagesWidth: 80,
            imagesHeight: 87,
            title: "Turn your notes into flashcards instantly",
            description: "Upload your class notes or paste any text into the text box",
            linkText: "Create flashcards from a note",
            shortLinkText: "Create flashcards",
        },
        {
            images: [ExamIcon],
            alt: "turn your notes into practice quizzes",
            imagesWidth: 76,
            imagesHeight: 80,
            title: "Turn your notes into practice quizzes",
            description: "Test your understanding of the material by quizzing yourself.",
            linkText: "Take a practice quiz",
            shortLinkText: "Take a quiz",
        },
    ],
};

const flashcardMakerContent: VideoWithTwoLinkedCard = {
    videoComponent: {
        title: "Try our AI-powered Flashcard Generator",
        subtitle: "Create flashcards quickly from your lecture notes and other material.",
        linkText: "Create with AI",
        shortLinkText: "Create with AI",
        link: LANDING_LINKS.AI_FLASHCARDS,
        videoId: "899614041",
    },
    linkedCards: [
        {
            images: [FlashcardIcon],
            alt: "click to make your flashcards",
            imagesWidth: 100,
            imagesHeight: 109,
            title: "Create flashcards on Knowt",
            description:
                "Create flashcard sets using our free flashcard maker. Write our terms & definitions, plus add images from your own library for free.",
            linkText: "Make flashcards",
            shortLinkText: "Make flashcards",
            link: LANDING_LINKS.AI_FLASHCARDS,
        },
        {
            images: [QuizletIcon, ImportArrowIcon, KnowtLogo],
            alt: "click to import flashcards from Quizlet",
            imagesWidth: 61,
            imagesHeight: 63,
            title: "Import flashcards from Quizlet",
            description: "Import Quizlet sets into Knowt with a single click & study it for free.",
            linkText: "Import from Quizlet",
            shortLinkText: "Import from Quizlet",
            link: LANDING_LINKS.AI_FLASHCARDS,
        },
    ],
};

export const videoWithTwoLinkedCardsContent: Record<UploadLandingPageTopic, VideoWithTwoLinkedCard | null> = {
    [UploadLandingPageTopic.VideoSummarizer]: learnWithTextingContent,
    [UploadLandingPageTopic.LectureNoteTaker]: lectureNoteTakerContent,
    [UploadLandingPageTopic.PDFSummarizer]: pdfSummarizerToHelpContent,
    [UploadLandingPageTopic.PPTSummarizer]: pptSummarizerToHelpContent,
    [UploadLandingPageTopic.SpreadSheetSummarizer]: spreadsheetSummarizerToHelpContent,
    [UploadLandingPageTopic.AINotes]: aiNotesContent,
    [UploadLandingPageTopic.AIFlashcards]: null, // TODO: fill this in
    [UploadLandingPageTopic.School]: null,
    [UploadLandingPageTopic.Fiveable]: null,
    [UploadLandingPageTopic.FlashcardMaker]: flashcardMakerContent,
};

export type DoMoreWithLessTimeCard = {
    left1: {
        title: string;
        desc: string;
        linkText?: string;
        link?: string;
        src?: string;
        videoId?: string;
        isSmallImage?: boolean;
    };
    left2: {
        title: string;
        desc: string;
        linkText?: string;
        link?: string;
        src?: string;
        videoId?: string;
        isSmallImage?: boolean;
    };
    right: {
        title: string;
        desc: string;
        linkText: string;
        link: string;
        comingSoon?: boolean;
        src: string;
    };
};

const doMoreWithLessTimeVideo: DoMoreWithLessTimeCard = {
    left1: {
        title: "Cheaper than ChatGPT",
        desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
        linkText: "AI Video Summarizer",
        link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
        videoId: "899614007",
    },
    left2: {
        title: "Instant AI Flashcards from Videos",
        desc: "Kai will watch your video, find the key details, and make flashcards for you to practice.",
        linkText: "Upload a Video",
        link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
        videoId: "899614064",
    },
    right: {
        title: "Summarize Videos on the Go",
        desc: "Pick up where you left off on your videos by studying from your phone.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        comingSoon: true,
        src: `${ASSETS_URL}/images/mobile-video-summarizer.png`,
    },
};

const doMoreWithLessTimeLectureNoteTaker: DoMoreWithLessTimeCard = {
    left1: {
        title: "Customize your notes",
        desc: "If Kai’s AI notetaker isn’t exactly how you want, tell him what you want him to change to make it better.",
        linkText: "Get notes made from your lecture",
        link: LANDING_LINKS.AI_LECTURE_NOTE_TAKER,
        // FIXME  videoId might not be correct, get it from Ramya or Abheek
        videoId: "899614007",
    },
    left2: {
        title: "This AI Note Taker is more than just a summarizer.",
        desc: "Kai can make flashcards on important terms mentioned in lecture & quiz you on important topics.",
        linkText: "Get Started",
        link: LANDING_LINKS.AI_LECTURE_NOTE_TAKER,
        // FXIME  videoId might not be correct, get it from Ramya or Abheek
        videoId: "899614064",
    },
    right: {
        title: "Record lectures from your phone",
        desc: "The Knowt app records lectures and takes notes for you. Switch seamlessly between the app & website.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        comingSoon: false,
        // FIXME:  src might not be correct, get it from Ramya or Abheek
        src: `${ASSETS_URL}/images/mobile-video-summarizer.png`,
    },
};

const doMoreWithLessTimePDF: DoMoreWithLessTimeCard = {
    left1: {
        title: "Chat with PDF & Ask Questions to Kai",
        desc: "Understand the document’s key points better by asking for Kai’s insight on your questions.",
        linkText: "AI PDF Summarizer",
        link: LANDING_LINKS.AI_PDF_SUMMARIZER,
        videoId: "899614027",
    },
    left2: {
        title: "Cheaper than ChatGPT",
        desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
        linkText: "Upload a PDF",
        link: LANDING_LINKS.AI_PDF_SUMMARIZER,
        videoId: "899614041",
    },
    right: {
        title: "Summarize PDFs on the Go",
        desc: "Pick up where you left off on your videos by studying from your phone.",
        linkText: "Upload a PDF",
        link: LANDING_LINKS.AI_PDF_SUMMARIZER,
        comingSoon: false,
        src: `${ASSETS_URL}/images/equations-screenshot.png`,
    },
};

const doMoreWithLessTimePPT: DoMoreWithLessTimeCard = {
    left1: {
        title: "Instant PPT Summaries",
        desc: "Kai will read through your PPT, find the key details, and make you a summary of the important stuff.",
        linkText: "AI PPT Summarizer",
        link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
        videoId: "899614027",
    },
    left2: {
        title: "Flashcards ready for you",
        desc: "Kai will read through your PPT, find the key details, and make flashcards for you to practice. ",
        linkText: "Upload a PPT",
        link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
        videoId: "899614041",
    },
    right: {
        title: "Mark up your PPT with annotations and comments",
        desc: "Our PDF editor lets you write directly on your uploaded files.",
        linkText: "Upload a PPT",
        link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
        comingSoon: false,
        src: `${ASSETS_URL}/images/ppt-screenshot.png`,
    },
};

const doMoreWithLessTimeSpreadsheet: DoMoreWithLessTimeCard = {
    left1: {
        title: "Instant Spreadsheet Summaries",
        desc: "Kai will read through your Spreadsheet, find the key details, and make you a summary of the important stuff.",
        linkText: "Spreadsheet Summarizer",
        link: LANDING_LINKS.AI_SPREADSHEET_SUMMARIZER,
        videoId: "899614027",
    },
    left2: {
        title: "Flashcards ready for you",
        desc: "Kai will read through your PPT, find the key details, and make flashcards for you to practice. ",
        linkText: "Upload a Spreadsheet",
        link: LANDING_LINKS.AI_SPREADSHEET_SUMMARIZER,
        videoId: "899614041",
    },
    right: {
        title: "Mark up your Spreadsheet with annotations and comments",
        desc: "Our Spreadsheet editor lets you write directly on your uploaded files.",
        linkText: "Upload a Spreadsheet",
        link: LANDING_LINKS.AI_SPREADSHEET_SUMMARIZER,
        comingSoon: false,
        src: `${ASSETS_URL}/images/equations-screenshot.png`,
    },
};

const doMoreWithLessTimeAINotes: DoMoreWithLessTimeCard = {
    left1: {
        title: "AI powered notetaking",
        desc: "Prompt the AI and ask it to help you kickstart your essay, outline, and more.",
        linkText: "Start a new note",
        link: LANDING_LINKS.AI_FLASHCARDS,
        videoId: "899614079",
    },
    left2: {
        title: "Note formatting",
        desc: "Highlight, Bold, Italicize, and more. It’s just like using Google Docs. ",
        linkText: "Start a new note",
        link: LANDING_LINKS.AI_FLASHCARDS,
        src: `${ASSETS_URL}/images/highlight-screenshot.png`,
    },
    right: {
        title: "Write and review notes on the go",
        desc: "Pick up where you left off on your flashcard sets by studying from your phone.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        comingSoon: false,
        src: `${ASSETS_URL}/images/note-screenshot.png`,
    },
};

const doMoreWithLessTimeAIFlashcards: DoMoreWithLessTimeCard = {
    left1: {
        title: "Learn mode",
        link: LANDING_LINKS.LEARN_MODE,
        desc: "Learn mode helps you intelligently go through all your flashcards with multiple choice, written, and true and false questions.",
        videoId: "899614093",
    },
    left2: {
        title: "Practice test",
        link: LANDING_LINKS.LEARN_MODE,
        desc: "Practice test mode turns your flashcards into a test-like environment with multiple choice, true/false and other question types.",
        videoId: "899614101",
    },
    right: {
        title: "Study your AI Flashcards on the go",
        desc: "Pick up where you left off on your flashcard sets by studying from your phone.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        src: `${ASSETS_URL}/images/study-screenshot.png`,
    },
};

const doMoreWithLessTimeSchool: DoMoreWithLessTimeCard = {
    left1: {
        title: "Track student progress on flashcards",
        desc: "View individual student’s mastery percentages,  how long they’ve spent studying, and when they last logged on.",
        src: `${ASSETS_URL}/images/progress-card.svg`,
    },
    left2: {
        title: "Share files with your students",
        desc: "Newly created files will be automatically shared with students in your class.",
        src: `${ASSETS_URL}/images/class-card.png`,
    },
    right: {
        title: "Access your classroom on the go",
        desc: "Quickly see progress, share files with students, and more.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        src: `${ASSETS_URL}/images/class-mobile-dashboard.png`,
    },
};

const doMoreWithLessTimeFiveable: DoMoreWithLessTimeCard = {
    left1: {
        title: "The Only Free Response Room of it’s Kind",
        desc: "Run through multiple rounds of FRQs, get detailed explanations when you’re wrong and get better with each FRQ you do.",
        linkText: "FRQ Room",
        link: LANDING_LINKS.FREE_RESPONSE_PRACTICE_ROOM,
        src: `${ASSETS_URL}/images/kai-game-show.svg`,
        isSmallImage: true,
    },
    left2: {
        title: "In-Depth Study Guides",
        desc: "Find that one ultimate study guide that covers every single key topic, main event, and anything your need to know to get ready for you AP exam.",
        linkText: "AP Study Guides ",
        link: LANDING_LINKS.EXAM_PRACTICE_ROOM,
        src: `${ASSETS_URL}/images/study-guide.svg`,
        isSmallImage: true,
    },
    right: {
        title: "Brush up on your AP Vocabulary on the go",
        desc: "Review important terms you’ll need to know for your exam on the go with our free mobile app.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        src: `${ASSETS_URL}/images/study-screenshot.png`,
    },
};

const doMoreWithLessTimeFlashcardMaker: DoMoreWithLessTimeCard = {
    left1: {
        title: "Learn mode",
        link: LANDING_LINKS.LEARN_MODE,
        desc: "Learn mode helps you intelligently go through all your flashcards with multiple choice, written, and true and false questions.",
        src: `${ASSETS_URL}/images/learn-mode-screenshot.png`,
    },
    left2: {
        title: "Practice test",
        link: LANDING_LINKS.LEARN_MODE,
        desc: "Practice test mode turns your flashcards into a test-like environment with multiple choice, true/false and other question types.",
        src: `${ASSETS_URL}/images/practice-test-screenshot.png`,
    },
    right: {
        title: "Study Online Flashcards on the Go",
        desc: "Quickly see progress, share files with students, and more.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        src: `${ASSETS_URL}/images/study-screenshot.png`,
    },
};

export const doMoreWithLessTimeCardsContent: Record<UploadLandingPageTopic, DoMoreWithLessTimeCard | null> = {
    [UploadLandingPageTopic.VideoSummarizer]: doMoreWithLessTimeVideo,
    [UploadLandingPageTopic.LectureNoteTaker]: doMoreWithLessTimeLectureNoteTaker,
    [UploadLandingPageTopic.PDFSummarizer]: doMoreWithLessTimePDF,
    [UploadLandingPageTopic.PPTSummarizer]: doMoreWithLessTimePPT,
    [UploadLandingPageTopic.SpreadSheetSummarizer]: doMoreWithLessTimeSpreadsheet,
    [UploadLandingPageTopic.AINotes]: doMoreWithLessTimeAINotes,
    [UploadLandingPageTopic.AIFlashcards]: doMoreWithLessTimeAIFlashcards,
    [UploadLandingPageTopic.School]: doMoreWithLessTimeSchool,
    [UploadLandingPageTopic.Fiveable]: doMoreWithLessTimeFiveable,
    [UploadLandingPageTopic.FlashcardMaker]: doMoreWithLessTimeFlashcardMaker,
};

export const smartCardsDoMoreWithLessTimeFeedbacks = [
    {
        name: "Macie Allen",
        grade: "College Freshman",
        desc: "I loved knowt for vocab flashcards! Especially to import from quizlet with a link literally chefs kisss.",
        src: `${ASSETS_URL}/images/marcie-allen-profile.png`,
    },
    {
        name: "Sam Loos",
        grade: "High School Senior",
        desc: "I used knowt to study for my apush midterm and it saved my butt! The import from quizlet feature helped a ton too. Slayed that test with an A!! 😻😻😻",
        src: `${ASSETS_URL}/images/sam-loos-profile.png`,
    },
];

export const lectureNoteTakerDoMoreWithLessTimeFeedbacks = [
    {
        name: "Arushi Bhatia",
        grade: "Villanova University",
        desc: "I haven’t watched my lectures in months -- Knowt helps me get notes and practice so quickly that I never need to!",
        // FIXME:  src might not be correct, get it from Ramya or Abheek
        src: `${ASSETS_URL}/images/marcie-allen-profile.png`,
    },
    {
        name: "Shivangi Sood",
        grade: "Northeastern University",
        desc: "I open Knowt a day or two before my exam, upload my lecture and start studying instantly. It’s save me so much time in studying, and I’ve actually gotten better grades too.",
        // FIXME:  src might not be correct, get it from Ramya or Abheek
        src: `${ASSETS_URL}/images/sam-loos-profile.png`,
    },
];

const schoolDoMoreWithLessTimeFeedbacks = [
    {
        name: "Utilize sections for sharing",
        desc: "Teach multiple periods of the same class? Use sections to separate students into separate groups.",
        src: `${ASSETS_URL}/images/class-people-tab.svg`,
        isFeature: true,
    },
];

const fiveableDoMoreWithLessTimeFeedbacks = [
    {
        name: "Practice Test Room",
        desc: "Test yourself on key concepts with unlimited multiple choice questions designed to challenge you.",
        isFeature: true,
        linkText: "AP Practice Test Room",
        href: LANDING_LINKS.EXAM_PRACTICE_ROOM,
    },
];

const flashcardMakerDoMoreWithLessTimeFeedbacks = [
    {
        name: "Spaced Repetition",
        desc: "Our spaced repetition algorithm is scientifically backed, and is ideal for when you start studying ahead of time and have large flashcard sets. ",
        isFeature: true,
    },
];

export const doMoreWithLessTimeFeedbacks = {
    [UploadLandingPageTopic.VideoSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.LectureNoteTaker]: lectureNoteTakerDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.PDFSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.PPTSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.SpreadSheetSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.AINotes]: smartCardsDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.AIFlashcards]: smartCardsDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.School]: schoolDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.Fiveable]: fiveableDoMoreWithLessTimeFeedbacks,
    [UploadLandingPageTopic.FlashcardMaker]: flashcardMakerDoMoreWithLessTimeFeedbacks,
};

export const smartCardsLinkedCards: LinkedCard[] = [
    {
        images: [BrainIcon],
        alt: "turn your notes into flashcards instantly",
        imagesWidth: 83,
        imagesHeight: 80,
        title: "Free Learn Mode",
        description: "Keep studying the way that works best for you - for free.",
        link: LANDING_LINKS.AI_FLASHCARDS,
        linkText: "Try Learn Mode",
        shortLinkText: "Try Learn Mode",
    },
    {
        images: [QuizletIcon, ImportArrowIcon, KnowtLogo],
        alt: "turn your notes into practice quizzes",
        imagesWidth: 61,
        imagesHeight: 63,
        title: "The only free Quizlet alternative you’ll need",
        description: "Import Quizlet sets into Knowt with a single click & study it for free.",
        link: LANDING_LINKS.AI_FLASHCARDS,
        linkText: "Import a set from Quizlet",
        shortLinkText: "Import a set from Quizlet",
    },
];

export const examPracticeAndFRQLinkedCards: LinkedCard[] = [
    {
        images: [CrownIcon],
        alt: "press to practice exam",
        imagesWidth: 102,
        imagesHeight: 102,
        title: "Unlimited Multiple Choice Practice Questions",
        description: "Practice makes perfect! Test yourself on concepts with MCQ questions.",
        link: LANDING_LINKS.EXAM_PRACTICE_ROOM,
        linkText: "Practice Test Room",
        shortLinkText: "Practice Test Room",
    },
    {
        images: [SwordIcon],
        alt: "press to go to free response practice room",
        imagesWidth: 83,
        imagesHeight: 80,
        title: "Get Graded on Sample Free Response Questions",
        description: "Take a stab at our huge library of FRQs & grade them yourself OR have Kai help you out.",
        link: LANDING_LINKS.FREE_RESPONSE_PRACTICE_ROOM,
        linkText: "Free Response Practice Room",
        shortLinkText: "Free Response Practice Room",
    },
];

type EndlessWaysCard = {
    title: string;
    desc: string;
    linkText: string;
    link: string;
    src?: string;
    alt?: string;
};

const videoSummarizerData: EndlessWaysCard = {
    title: "Video Summarizer",
    desc: "Kai will watch your lecture videos, turn them into notes and flashcards instantly.",
    linkText: "AI Video Summarizer",
    link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
    src: `${ASSETS_URL}/images/video-summarizer-screenshot.svg`,
    alt: "Click to learn more about Video Summarizer",
};

const lectureVideoData: EndlessWaysCard = {
    title: "Already have a video?",
    desc: "If you already have a video of your lecture & want to summarizer it, just upload it to our video summarizer.",
    linkText: "AI Video Summarizer",
    link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
    // FIXME:  src might not be correct, get it from Ramya or Abheek
    src: `${ASSETS_URL}/images/video-summarizer-screenshot.svg`,
    alt: "Click to learn more about Video Summarizer",
};

const lecturePPTData: EndlessWaysCard = {
    title: "Have lecture slides on hand?",
    desc: "Upload your lecture slides and we’ll summarize it into an outline with our note take.",
    linkText: "AI PPT Summarizer",
    link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
    src: `${ASSETS_URL}/images/video-summarizer-screenshot.svg`,
    alt: "Click to learn more about Powerpoint Summarizer",
};

const pdfSummarizerData: EndlessWaysCard = {
    title: "Make flashcards from PDFs",
    desc: "Kai will read your PDF, find the key details for you and make notes and flashcards for you to practice",
    linkText: "AI PDF Summarizer",
    link: LANDING_LINKS.AI_PDF_SUMMARIZER,
    src: `${ASSETS_URL}/images/pdf-summarizer.png`,
    alt: "Click to learn more about PDF Summarizer",
};

const pptSummarizerData: EndlessWaysCard = {
    title: "Make flashcards from powerpoints",
    desc: "Kai will read your PPT, make notes and flashcards instantly.",
    linkText: "AI PPT Summarizer",
    link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
    src: `${ASSETS_URL}/images/kai-sherlock.svg`,
    alt: "Click to learn more about PPT Summarizer",
};

const aiFlashcardsData: EndlessWaysCard = {
    title: "AI Flashcards",
    desc: "You can make flashcards and study them for free with learn mode, spaced repetition, and more.",
    linkText: "AI Flashcards",
    link: LANDING_LINKS.AI_FLASHCARDS,
};

const aiNoteEditorData: EndlessWaysCard = {
    title: "AI Note Edtior",
    desc: "Prefer to take your own notes? Optimize the process with the help of Kai.",
    linkText: "AI Notes",
    link: LANDING_LINKS.AI_NOTES,
};

const aiNotesData: EndlessWaysCard = {
    title: "Make flashcards from notes",
    desc: "You can take notes and Kai will make flashcards from them instantly. ",
    linkText: "AI Notes",
    link: LANDING_LINKS.AI_NOTES,
};

// TODO: revisit the data here, some of them are duplicated to save time
export const endlessWaysCardsContent: Record<
    UploadLandingPageTopic,
    { card1: EndlessWaysCard; card2: EndlessWaysCard; card3: EndlessWaysCard; card4: EndlessWaysCard } | null
> = {
    [UploadLandingPageTopic.VideoSummarizer]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: aiNotesData,
    },
    [UploadLandingPageTopic.LectureNoteTaker]: {
        card1: lectureVideoData,
        card2: lecturePPTData,
        card3: aiFlashcardsData,
        card4: aiNoteEditorData,
    },
    [UploadLandingPageTopic.PDFSummarizer]: {
        card1: videoSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: aiNotesData,
    },
    [UploadLandingPageTopic.PPTSummarizer]: {
        card1: pdfSummarizerData,
        card2: videoSummarizerData,
        card3: aiFlashcardsData,
        card4: aiNotesData,
    },
    [UploadLandingPageTopic.SpreadSheetSummarizer]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: videoSummarizerData,
    },
    [UploadLandingPageTopic.AINotes]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: videoSummarizerData,
    },
    [UploadLandingPageTopic.AIFlashcards]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: videoSummarizerData,
        card4: aiNotesData,
    },
    [UploadLandingPageTopic.School]: null,
    [UploadLandingPageTopic.Fiveable]: null,
};

export const AI_GENERATION_TOOL_BENEFITS = {
    [UploadLandingPageTopic.VideoSummarizer]: [
        {
            title: "The Video Summarizer built to help you learn.",
            desc: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Quiz yourself on those long videos",
            desc: "Kai will quiz you! Studies show that’s a lot more effective than watching.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Upload Videos",
                href: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
            },
        },
        {
            title: "Summarize Youtube Videos with the Knowt Extension",
            desc: "Use our chrome extension to upload from the most popular sites.",
            color: themeColors.pdfLight,
            img: <TextSearch />,
            button: {
                text: "Download extension",
                href: LANDING_LINKS.CHROME_EXTENSION,
            },
        },
        {
            title: "Chat & Ask AI any Questions about your Videos",
            desc: "Understand your video’s key points better by asking for Kai’s insight on your questions.",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Summarize videos on the Go",
            desc: "Pick up where you left off on your videos or other files by studying from your phone.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "The Cheapest Video Summarizer Available",
            desc: "Pay less than chatgpt & get more useful insights built specifically for students.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Explore Plans",
                href: LANDING_LINKS.PLANS,
            },
        },
    ],
    [UploadLandingPageTopic.LectureNoteTaker]: [
        {
            title: "Go from your lecture to notes in seconds",
            desc: "After class, you can shoot Kai a text & he’ll answer your questions just like your teacher would.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Make practice tests from lecture notes",
            desc: "Kai will quiz you, which is a lot more effective than just reading.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Record Lecture",
                href: LANDING_LINKS.AI_LECTURE_NOTE_TAKER,
            },
        },
        {
            title: "Summarize your lecture exactly how you would like",
            desc: "Once Kai takes notes, you can have him customize them to be the exact format, detail and tone you’d like.",
            color: themeColors.pdfLight,
            img: <TextSearch />,
        },
        {
            title: "This AI Note Taker is more than just a summarizer.",
            desc: "You can chat with your lecture using Kai or make flashcards on key terms!",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Record lectures from your phone",
            desc: "The Knowt app records lectures and takes notes for you. Switch seamlessly between app & website.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "Cheapest AI Note Taker",
            desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Explore Plans",
                href: LANDING_LINKS.PLANS,
            },
        },
    ],
    [UploadLandingPageTopic.PDFSummarizer]: [
        {
            title: "The PDF Summarizer built to help you learn.",
            desc: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Quiz yourself on any PDF documents",
            desc: "Kai will quiz you! Studies show that’s a lot more effective than reading.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Upload PDF",
                href: LANDING_LINKS.AI_PDF_SUMMARIZER,
            },
        },
        {
            title: "Analyze & Summarize PDFs from any site",
            desc: "Use our chrome extension to upload from the most popular sites.",
            color: themeColors.pdfLight,
            img: <TextSearch />,
            button: {
                text: "Download extension",
                href: LANDING_LINKS.CHROME_EXTENSION,
            },
        },
        {
            title: "Chat & Ask Questions with AI",
            desc: "Understand the document’s key points better by asking for Kai’s insight on your questions.",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Summarize PDFs on the Go",
            desc: "Pick up where you left off on your PDF or other files  by studying from your phone.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "Cheaper than ChatGPT",
            desc: "Pay less & get more useful insights with a video summarizer built specifically for students.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Explore Plans",
                href: LANDING_LINKS.PLANS,
            },
        },
    ],
    [UploadLandingPageTopic.PPTSummarizer]: [
        {
            title: "The PPT Summarizer built to help you learn.",
            desc: "It’s as simple as sending a text to Kai, and he’ll explain anything in your PPT to you like a tutor would.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Quiz yourself on any Powerpoint Slides",
            desc: "Kai will quiz you! Studies show that’s a lot more effective than reading.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Upload Slides",
                href: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
            },
        },
        {
            title: "Do more in less time with the Knowt AI PPT Summarizer",
            desc: "Use our chrome extension to upload from the most popular sites.",
            color: themeColors.pdfLight,
            img: <TextSearch />,
            button: {
                text: "Download extension",
                href: LANDING_LINKS.CHROME_EXTENSION,
            },
        },
        {
            title: "Create flashcards from powerpoint slides",
            desc: "Kai will read through your PPT, find the key details, and make flashcards for you to practice.",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Instant PPT Summaries on the Go",
            desc: "Go from ppt to notes ai anywhere you are with the mobile app.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "Simplify your PPTs with AI without paying much!",
            desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Explore Plans",
                href: LANDING_LINKS.PLANS,
            },
        },
    ],
    [UploadLandingPageTopic.SpreadSheetSummarizer]: [
        {
            title: "The PDF Summarizer built to help you learn.",
            desc: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Quiz yourself on any PDF documents",
            desc: "Kai will quiz you! Studies show that’s a lot more effective than reading.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Upload PDF",
                href: LANDING_LINKS.AI_PDF_SUMMARIZER,
            },
        },
        {
            title: "Analyze & Summarize PDFs from any site",
            desc: "Use our chrome extension to upload from the most popular sites.",
            color: themeColors.pdfLight,
            img: <Settings2 />,
            button: {
                text: "Download extension",
                href: LANDING_LINKS.CHROME_EXTENSION,
            },
        },
        {
            title: "Chat with PDF & Ask Questions to Kai",
            desc: "Understand the document’s key points better by asking for Kai’s insight on your questions.",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Summarize PDFs on the Go",
            desc: "Pick up where you left off on your PDF or other files  by studying from your phone.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "Cheaper than ChatGPT",
            desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
    ],
    [UploadLandingPageTopic.AINotes]: [
        {
            title: "The PDF Summarizer built to help you learn.",
            desc: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Quiz yourself on any PDF documents",
            desc: "Kai will quiz you! Studies show that’s a lot more effective than reading.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Upload PDF",
                href: LANDING_LINKS.AI_PDF_SUMMARIZER,
            },
        },
        {
            title: "Analyze & Summarize PDFs from any site",
            desc: "Use our chrome extension to upload from the most popular sites.",
            color: themeColors.pdfLight,
            img: <Settings2 />,
            button: {
                text: "Download extension",
                href: LANDING_LINKS.CHROME_EXTENSION,
            },
        },
        {
            title: "Chat with PDF & Ask Questions to Kai",
            desc: "Understand the document’s key points better by asking for Kai’s insight on your questions.",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Summarize PDFs on the Go",
            desc: "Pick up where you left off on your PDF or other files  by studying from your phone.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "Cheaper than ChatGPT",
            desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
    ],
    [UploadLandingPageTopic.AIFlashcards]: [
        {
            title: "The PDF Summarizer built to help you learn.",
            desc: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
            color: themeColors.videoLight,
            img: <BrainCircuit />,
        },
        {
            title: "Quiz yourself on any PDF documents",
            desc: "Kai will quiz you! Studies show that’s a lot more effective than reading.",
            color: themeColors.docsLight,
            img: <BookCheck />,
            button: {
                text: "Upload PDF",
                href: LANDING_LINKS.AI_PDF_SUMMARIZER,
            },
        },
        {
            title: "Analyze & Summarize PDFs from any site",
            desc: "Use our chrome extension to upload from the most popular sites.",
            color: themeColors.pdfLight,
            img: <Settings2 />,
            button: {
                text: "Download extension",
                href: LANDING_LINKS.CHROME_EXTENSION,
            },
        },
        {
            title: "Chat with PDF & Ask Questions to Kai",
            desc: "Understand the document’s key points better by asking for Kai’s insight on your questions.",
            color: themeColors.excelLight,
            img: <MessageCircle />,
        },
        {
            title: "Summarize PDFs on the Go",
            desc: "Pick up where you left off on your PDF or other files  by studying from your phone.",
            color: themeColors.flashcardsLight,
            img: <Library />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
        {
            title: "Cheaper than ChatGPT",
            desc: "Pay less & get more useful insights with a video summarizer built specifically for studemts.",
            color: themeColors.neutral1,
            img: <CircleDollarSign />,
            button: {
                text: "Download app",
                href: LANDING_LINKS.MOBILE,
            },
        },
    ],
};
