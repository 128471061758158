import {
    FlexColumn,
    FlexColumnAlignJustifyCenter,
    FlexColumnJustifyCenter,
    FlexRowAlignCenter,
} from "@/components/Flex";
import LinkCard from "@/components/cards/LinkCard";
import Image from "next/image";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";
import { spacing } from "@/utils/spacing";
import Tabs from "@/components/Tabs";
import { useState } from "react";
import br from "@/styles/breakpoints.module.css";

const TwoColumnsLayout = ({
    largerOn,
    leftComponent,
    rightComponent,
}: {
    largerOn?: "left" | "right";
    leftComponent: React.ReactNode;
    rightComponent: React.ReactNode;
}) => {
    return (
        <FlexRowAlignCenter className={br.mdDownColumn} style={{ width: "100%", gap: "4rem", alignItems: "stretch" }}>
            <FlexColumn style={{ flex: largerOn === "left" ? 1.1 : 0.9 }}>{leftComponent}</FlexColumn>
            <FlexColumn style={{ flex: largerOn === "right" ? 1.1 : 0.9 }}>{rightComponent}</FlexColumn>
        </FlexRowAlignCenter>
    );
};

const STUDENT_CARDS = [
    [
        {
            title: "Live Lecture Note Taker",
            description:
                "Capture, organize, and summarize live lectures in real time for better learning and retention.",
            backgroundColor: "#FCEDD5",
            isLarger: true,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        paddingTop: spacing.MD,
                        paddingBottom: spacing.LG,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/knowt-liveNoteTaker-graphic.svg`}
                        alt="Live Lecture Note Taker"
                        width={484}
                        height={273}
                        style={{ width: "90%", objectFit: "contain" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.pureBlack,
            href: "/ai-lecture-note-taker",
        },
        {
            title: "PPT/ PDF Summarizer",
            description: "Kai will read your PPT and PDF to make notes and flashcards instantly.",
            backgroundColor: "#F0F5F2",
            isLarger: false,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        paddingTop: spacing.MD,
                        paddingBottom: spacing.LG,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/pdf-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={403}
                        height={231}
                        style={{ width: "90%", objectFit: "contain" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.pureBlack,
            href: "/ai-pdf-summarizer",
        },
    ],
    [
        {
            title: "Snap & Solve",
            description: "Snap a picture of your homework and get step-by-step guided solutions.",
            backgroundColor: "#F5EBF7",
            isLarger: false,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{ width: "100%", height: "100%", justifyContent: "flex-end", paddingTop: spacing.MD }}>
                    <Image
                        src={`${ASSETS_URL}/images/scan-screenshot-mobile.png`}
                        alt="Live Lecture Note Taker"
                        width={332}
                        height={280}
                        style={{ maxWidth: "80vw", height: "auto" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.pureBlack,
            href: "/mobile",
        },
        {
            title: "Learn Mode",
            description:
                "Learn mode helps you intelligently go through all your flashcards with multiple choice, written, and true and false questions.",
            backgroundColor: themeColors.docsLight,
            isLarger: true,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{ width: "100%", height: "100%", justifyContent: "flex-end", paddingTop: spacing.MD }}>
                    <Image
                        src={`${ASSETS_URL}/images/learn-mode-answer-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={450}
                        height={280}
                        style={{ width: "90%", height: "auto" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.neutralBlack,
            href: "/learn-mode",
        },
    ],
    [
        {
            title: "AP Exam Hub",
            description:
                "Your go-to hub for any AP exam with study materials, flashcards, notes, and practice tests all in one place.",
            backgroundColor: themeColors.videoLight,
            isLarger: true,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        paddingTop: spacing.MD,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/ap-chemistry-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={460}
                        height={300}
                        style={{ width: "90%", height: "auto" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.neutralBlack,
            href: "/exams/AP",
        },
        {
            title: "Practice Test",
            description:
                "Practice test mode turns your flashcards into multiple choice, true/false and other question types.",
            backgroundColor: themeColors.flashcardsLight,
            isLarger: false,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        paddingTop: spacing.MD,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/ap-practice-test-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={370}
                        height={270}
                        style={{ width: "90%", height: "auto" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.neutralBlack,
            href: "/exams/AP/practice-test-room",
        },
    ],
];

const TEACHER_CARDS = [
    [
        {
            title: "Live Lecture Note Taker",
            description:
                "Capture, organize, and summarize live lectures in real time for better learning and retention.",
            backgroundColor: "#FCEDD5",
            isLarger: true,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        paddingTop: spacing.MD,
                        paddingBottom: spacing.LG,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/knowt-liveNoteTaker-graphic.svg`}
                        alt="Live Lecture Note Taker"
                        width={484}
                        height={273}
                        style={{ width: "90%" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.pureBlack,
            href: "/ai-lecture-note-taker",
        },
        {
            title: "PPT/ PDF Summarizer",
            description: "Kai will read your PPT and PDF to make notes and flashcards instantly.",
            backgroundColor: "#F0F5F2",
            isLarger: false,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        paddingTop: spacing.MD,
                        paddingBottom: spacing.LG,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/pdf-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={403}
                        height={231}
                        style={{ width: "90%" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.pureBlack,
            href: "/ai-pdf-summarizer",
        },
    ],
    [
        {
            title: "AI Tools that save 10+ Hours Weekly",
            description: "With a few clicks create a plan for a topic or objective you're teaching",
            backgroundColor: "#F5EBF7",
            isLarger: false,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{ width: "100%", height: "100%", justifyContent: "flex-end", paddingTop: spacing.MD }}>
                    <Image
                        src={`${ASSETS_URL}/images/lesson-plan-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={344}
                        height={220}
                        style={{ width: "90%" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.pureBlack,
            href: "/free-ai-tools-for-teachers/ai-lesson-plan-generator",
        },
        {
            title: "Chat Assignments",
            description:
                "Interactive chat assignments help students explore and learn new topics through guided discussions.",
            backgroundColor: themeColors.docsLight,
            isLarger: true,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{ width: "100%", height: "100%", justifyContent: "flex-end", paddingTop: spacing.MD }}>
                    <Image
                        src={`${ASSETS_URL}/images/chat-assignment-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={500}
                        height={240}
                        style={{ width: "90%" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.neutralBlack,
            href: "/free-ai-tools-for-teachers",
        },
    ],
    [
        {
            title: "Share Materials and Monitor Progress",
            description: "Share resources effortlessly and track student progress efficiently for better learning.",
            backgroundColor: themeColors.videoLight,
            isLarger: true,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{ width: "100%", height: "100%", justifyContent: "flex-end", paddingTop: spacing.MD }}>
                    <Image
                        src={`${ASSETS_URL}/images/classroom-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={470}
                        height={270}
                        style={{ width: "90%" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.neutralBlack,
            href: "/free-ai-tools-for-teachers",
        },
        {
            title: "Practice Test",
            description:
                "Practice test mode turns your flashcards into multiple choice, true/false and other question types.",
            backgroundColor: themeColors.flashcardsLight,
            isLarger: false,
            bottomComponent: (
                <FlexColumnAlignJustifyCenter
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        paddingTop: spacing.MD,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/ap-practice-test-screen.png`}
                        alt="Live Lecture Note Taker"
                        width={370}
                        height={270}
                        style={{ width: "90%" }}
                    />
                </FlexColumnAlignJustifyCenter>
            ),
            textColor: themeColors.neutralBlack,
            href: "/exams/AP/practice-test-room",
        },
    ],
];

const StudentTeacherFeaturesTab = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const cards = selectedTab === 0 ? STUDENT_CARDS : TEACHER_CARDS;

    return (
        <FlexColumnAlignJustifyCenter>
            <Tabs
                tabs={[
                    { label: "For Students", value: "student" },
                    { label: "For Teachers", value: "teacher" },
                ]}
                selectedTab={selectedTab}
                onChange={(_tab, i) => setSelectedTab(i)}
                containerStyle={{ marginBottom: "4rem" }}
                tabsSx={{
                    "& .Mui-selected": { color: themeColors.pureBlack },
                    "& .MuiTabs-indicator": {
                        backgroundColor: themeColors.primary3,
                        color: themeColors.pureBlack,
                    },
                    backgroundColor: themeColors.flashcardsLight,
                    height: "6rem",
                }}
                tabSx={{
                    fontSize: "1.4rem",
                    ":not(.Mui-selected):hover": {
                        height: "80%",
                        backgroundColor: themeColors.flashcardsMed,
                        borderRadius: 999,
                        outline: "none",
                    },
                }}
            />
            <FlexColumnJustifyCenter style={{ width: "100%", gap: "4rem", alignItems: "stretch" }}>
                {cards.map((row, i) => (
                    <TwoColumnsLayout
                        key={i}
                        largerOn={row[0].isLarger ? "left" : "right"}
                        leftComponent={
                            <LinkCard
                                headerComponent={
                                    <FlexColumn style={{ maxWidth: row[0].isLarger ? "unset" : "27rem" }}>
                                        <span className="heading4">{row[0].title}</span>
                                        <span className="body2">{row[0].description}</span>
                                    </FlexColumn>
                                }
                                bottomComponent={row[0].bottomComponent}
                                style={{
                                    backgroundColor: row[0].backgroundColor,
                                    width: "100%",
                                    height: "100%",
                                }}
                                arrowIconProps={{
                                    size: 46,
                                    color: themeColors.pureWhite,
                                    style: {
                                        transform: "rotate(-45deg)",
                                    },
                                }}
                                textColor={row[0].textColor}
                                href={row[0].href}
                            />
                        }
                        rightComponent={
                            <LinkCard
                                headerComponent={
                                    <FlexColumn style={{ maxWidth: row[1].isLarger ? "unset" : "24rem" }}>
                                        <span className="heading4">{row[1].title}</span>
                                        <span className="body2">{row[1].description}</span>
                                    </FlexColumn>
                                }
                                bottomComponent={row[1].bottomComponent}
                                style={{
                                    backgroundColor: row[1].backgroundColor,
                                    width: "100%",
                                    height: "100%",
                                }}
                                arrowIconProps={{
                                    size: 46,
                                    color: themeColors.pureWhite,
                                    style: {
                                        transform: "rotate(-45deg)",
                                    },
                                }}
                                textColor={row[1].textColor}
                                href={row[1].href}
                            />
                        }
                    />
                ))}
            </FlexColumnJustifyCenter>
        </FlexColumnAlignJustifyCenter>
    );
};

export default StudentTeacherFeaturesTab;
