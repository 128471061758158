"use client";

import FAQ from "@/components/FAQ";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import Footer from "@/components/Footer";
import LogPageView from "@/components/LogPageVisit";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useAuthSlideContextSelector } from "@/features/Auth/AuthSlide/hook/useAuthSlideContext";
import { themeColors } from "@/utils/themeColors";
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { FrontendFeaturedCategory } from "@knowt/syncing/hooks/exams/utils";
import { wait } from "@knowt/syncing/utils/genericUtils";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ReviewType, USER_REVIEWS } from "../LandingMainPage/constants";
import { LandingHalfSection, LandingPageWrapper, LandingFullWidthSection } from "../components/styled/Containers";
import AIStudyToolsSection from "./components/AIStudyToolsSection";
import { LANDING_LINKS } from "./utils/aiGenerationPageConstants";
import StudentTeacherFeaturesTab from "./components/StudentTeacherFeaturesTab";
import CompetitorsComparisonTable from "../CompetitorPages/components/CompetitorsComparisionTable";
import { Hourglass, Library, School } from "lucide-react";
import Tabs from "@/components/Tabs";
import { ASSETS_URL } from "@/config/deployConstants";
import { FeedbackCard } from "../components/FeedbackCard";
import br from "@/styles/breakpoints.module.css";
import StatsCards from "../components/StatsCards";
import ExamPicker from "../ExamPages/components/ExamPicker";
import ExploreTopUserContents from "../components/ExploreTopUserContents";
import BenefitsCards from "@/features/TeacherAITools/components/BenefitsCards";
import { spacing } from "@/utils/spacing";
import BrowseEncouragementCards from "../components/BrowseEncouragmentCards";

const aiFlashcardsFAQs = [
    {
        question: "Why is Knowt the best free Quizlet alternative?",
        answer: (
            <span>
                3 million students & teachers have switched to Knowt and trust it for a good reason! It’s super easy to
                bring over your existing Quizlets to Knowt and start studying them completely free in seconds. You can
                go through unlimited rounds of our free learn mode, matching game, spaced repetition or practice test
                mode. Plus, if you want, you can also create your own flashcards or have AI{" "}
                <LinkWrapper className="knowtLink" href={LANDING_LINKS.AI_VIDEO_SUMMARIZER}>
                    make Flashcards from your lecture videos
                </LinkWrapper>
                , pdfs, notes, and more! If that's not for you, you can find millions of free flashcards made by other
                students at your fingertips. There’s a lot of great free study tools and resources to take advantage on
                Knowt that make it a great free quizlet alternative.
            </span>
        ),
    },
    {
        question: "How can I make new flashcards on Knowt?",
        answer: (
            <FlexColumn>
                <span>
                    Option 1: Easily make AI Flashcards from your lecture videos and notes in seconds using ai. It's as
                    easy as uploading your files and pressing a button!
                </span>
                <br />
                <span>
                    Option 2: Find a flashcard set on Quizlet and bring it over to Knowt to take advantage of our free
                    learn mode, free practice test mode and free games!
                </span>
                <br />
                <span>
                    Option 3: Just add a term and definition to make your flashcards, plus add images to flashcards for
                    free. You can also take advantage of our brand new AI Smart{" "}
                    <LinkWrapper className="knowtLink" href={LANDING_LINKS.AI_FLASHCARDS}>
                        Flashcard maker
                    </LinkWrapper>{" "}
                    to save time!
                </span>
            </FlexColumn>
        ),
    },
    {
        question: "What features are paid on Quizlet?",
        answer: "As of 2024, learn mode, practice tests, adding images & formatting to sets is paid on Quizlet. But, when you import those Quizlets onto Knowt, you can study them completely free without having to pay.",
    },
    {
        question: "How do I use learn mode on Knowt?",
        answer: "Learn mode is a great way to master your flashcards, especially on a time crunch. To study your flashcards using learn mode, open your flashcard set and click on learn mode from the study modes on the left hand side. You can choose to turn on written mode or changes the question types of the flashcards using the Options button on th top right. All the customizability & options also makes this a great free quizlet alternative.",
    },
    {
        question: "What Makes Knowt Better Than Quizlet?",
        answer: "Knowt isn't just a Quizlet replacement—we’re an upgrade. While other alternatives to quizlet just stop at flashcards, our  AI tools for students will create study guides, flashcards, notes, outlines, and much more for a fraction of the cost of any other site! For students looking for apps like Quizlet but free, you’ve found us: Knowt is a free study website like Quizlet that helps you study more efficiently by taking the notes and making flashcards for you, so you can get right to studying.  When compared to other study websites like Quizlet, it’s clear who’s the winner. Kai, your personalised AI study helper, can also answer any questions you have about notes, videos, PPTs, and even explain why you got a practice question wrong, making us the best AI study buddy. ",
    },
    {
        question: "Does Knowt Replace Traditional Study Methods?",
        answer: (
            <span>
                Yes! Working as your very own study buddy AI, our AI studying tool creates personalized materials that
                help you understand the subject better and faster. You’ll never have to spend hours writing down
                flashcards again; at just a click of the button, you can upload your notes, slides, lecture videos and
                get a breakdown of everything you need to know within seconds. With our AI-generated study guides, smart
                flashcards, and adaptive learning, you’ll never think of using outdated study methods; your AI study
                assists optimize the learning process. Even study on the go with our{" "}
                <LinkWrapper className="knowtLink" href={LANDING_LINKS.MOBILE}>
                    study app (available both on the App Store and Google Play)
                </LinkWrapper>
                , so the best AI for study notes is available wherever you are. Your days of sitting at a desk staring
                at your notes are over, and you’ll never need to go back.
            </span>
        ),
    },
    {
        question: "What Types of Files Can Knowt Take Notes On?",
        answer: "Our AI study tools allow you to upload various types of files and get notes instantly. Whether you need to get notes on a 40 page PDF or word document, unreasonably long PowerPoints (PPTs), a 3 hour long lecture video, or even just lecture audio, our study guide creator will make a detailed breakdown of the file. Our AI study guide generator instantly extracts key information, summarizes content, and create study guides, flashcards. Within seconds, you’ll have study cards ready to use.",
    },
    {
        question: "What Are the Different Types of Flashcard Study Modes on Knowt?",
        answer: "Knowt offers multiple flashcard study modes, giving students more flexibility than other free Quizlet alternatives. If you love Quizlet Learn Mode, you’ll be happy to know that Knowt has a free Learn Mode, letting you study your terms with multiple choice, true or false, fill in the blank, and flashcard options. In addition, you can study with spaced repetition mode, that helps you engrain your knowledge into your long term memory over studying it for a certain period of time, the matching game, quickly take a practice test generated from your notes to gauge how much you know, and simply traditional flashcards, now with sorting mode! Unlike other websites similar to Quizlet, Knowt doesn’t lock these features behind a paywall, making it the best Quizlet alternative with learn mode for all students.",
    },
    {
        question: `What Subjects Do Knowt’s AI Study Tools Work On?`,
        answer: (
            <span>
                Our AI study tools work for any subject, from math and science to history, literature, and foreign
                languages. No matter the study resource you want generated, we’ve got you covered. You can pick between
                an{" "}
                <LinkWrapper className="knowtLink" href={LANDING_LINKS.AI_PDF_SUMMARIZER}>
                    AI PDF Summarizer
                </LinkWrapper>
                ,{" "}
                <LinkWrapper className="knowtLink" href={LANDING_LINKS.AI_VIDEO_SUMMARIZER}>
                    AI Video Summarizer
                </LinkWrapper>
                ,{" "}
                <LinkWrapper className="knowtLink" href={LANDING_LINKS.AI_LECTURE_NOTE_TAKER}>
                    AI Note Taker
                </LinkWrapper>
                , and{" "}
                <LinkWrapper className="knowtLink" href={LANDING_LINKS.AI_POWERPOINT_SUMMARIZER}>
                    AI PPT Summarizer
                </LinkWrapper>
                .
            </span>
        ),
    },
    {
        question: "Can I Use Knowt to Study for AP Exams?",
        answer: (
            <span>
                Yes! Knowt is one of the best AI study tools for high schoolers preparing for AP exams. We even have an
                AP Hub that contains{" "}
                <LinkWrapper className="knowtLink" href={"/exams/AP/"}>
                    AP notes and flashcards
                </LinkWrapper>{" "}
                from students that ACTUALLY took the exam , and realistic{" "}
                <LinkWrapper className="knowtLink" href={"/exams/AP/practice-test-room"}>
                    AP practice tests
                </LinkWrapper>{" "}
                & practice{" "}
                <LinkWrapper className="knowtLink" href={"/exams/AP/frq-room"}>
                    FRQs for your AP exam
                </LinkWrapper>{" "}
                to see your progress. If you need more study guides than are in our AP Hub, Our AI study guide generator
                can create structured outlines & helping you focus on test-specific content. Students can also use the
                AI study buddy to quiz themselves with practice tests, spaced repetition flashcards, and spaced
                repetition flashcards, and AI-powered explanations of difficult topics. Using our AP online study tools
                along with our AI study buddy, you’ll be getting that 5 in no time. We’ve also go study tools for other
                exams like our{" "}
                <LinkWrapper className="knowtLink" href={"/exams/IB"}>
                    IB revision notes on the IB Hub!
                </LinkWrapper>
            </span>
        ),
    },
    {
        question: "What Features Are Paid on Quizlet?",
        answer: (
            <FlexColumn>
                <span>
                    Quizlet used to be one of the most popular study tools, but many key features have now been locked
                    behind a paywall. And let’s be honest, students can’t always afford to pay a subscription. That’s
                    where we step in. If you’re looking for a Quizlet free alternative, Knowt is the best Quizlet
                    replacement that gives you everything Quizlet Learn Free used to offer—at no cost. On Quizlet, the
                    following features require a paid subscription:
                </span>
                <ul>
                    <li>
                        <span>
                            Quizlet Learn Alternative – Once free, now requires Quizlet Plus. Knowt provides a free
                            version of Quizlet Learn with AI-powered flashcards and spaced repetition.
                        </span>
                    </li>
                    <li>
                        <span>
                            Unlimited Practice Tests – Quizlet limits test mode access, but Knowt allows students to
                            take as many AI-generated practice tests as they want.
                        </span>
                    </li>
                    <li>
                        <span>
                            Custom Study Guides – Quizlet makes you pay for AI-powered study guides, but Knowt’s AI
                            study guide generator creates structured guides for any subject, free for all students.
                        </span>
                    </li>
                </ul>
                <span>
                    If you’re searching for apps like Quizlet but free, or simply study sites like Quizlet, look no
                    further. Knowt is the ultimate solution.
                </span>
            </FlexColumn>
        ),
    },
    {
        question: "Does Knowt Replace Traditional Study Methods?",
        answer: "Yes! Working as your very own study buddy AI, our AI studying tool creates personalized materials that help you understand the subject better and faster. You’ll never have to spend hours writing down flashcards again; at just a click of the button, you can upload your notes, slides, lecture videos and get a breakdown of everything you need to know within seconds. With our AI-generated study guides, smart flashcards, and adaptive learning, you’ll never think of using outdated study methods; your AI study assists optimize the learning process. Even study on the go with our study app (available both on the App Store and Google Play), so the best AI for study notes is available wherever you are. Your days of sitting at a desk staring at your notes are over, and you’ll never need to go back. ",
    },
    {
        question: "What Types of Files Can Knowt Take Notes On?",
        answer: "Our AI study tool allow you to upload various types of files and get notes instantly. Whether you need to get notes on a 40 page PDF or word document, unreasonably long PowerPoints (PPTs), a 3 hour long lecture video, or even just lecture audio, our study guide creator will make a detailed breakdown of the file. Our AI study guide generator instantly extracts key information, summarizes content, and create study guides, flashcards. Within seconds, you’ll have study cards ready to use, much faster than manually doing it yourself.",
    },
    {
        question: "What Are the Different Types of Flashcard Study Modes on Knowt?",
        answer: "Knowt offers multiple flashcard study modes, giving students more flexibility than other free Quizlet alternatives. If you love Quizlet Learn Mode, you’ll be happy to know that Knowt has an Learn Mode, letting you study your terms with multiple choice, true or false, fill in the blank, and flashcard options. In addition, you can study with spaced repetition mode, that helps you engrain your knowledge into your long term memory over studying it for a certain period of time, the matching game, quickly take a practice test generated from your notes to gauge how much you know, and simply traditional flashcards, now with sorting mode! Unlike other websites similar to Quizlet, Knowt doesn’t lock these features behind a paywall, making it the best Quizlet alternative with learn mode for all students.",
    },
];

const UploadMainLandingPage = ({
    featuredSubjectCategories,
    topNotes,
    topFlashcardSets,
    fromVerify = false,
    fromReset = false,
    fromLogin = false,
    fromSignup = false,
}: {
    featuredSubjectCategories: FrontendFeaturedCategory[];
    topNotes: Note[];
    topFlashcardSets: FlashcardSet[];
    fromVerify?: boolean;
    fromReset?: boolean;
    fromLogin?: boolean;
    fromSignup?: boolean;
}) => {
    const searchParams = useSearchParams();
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);

    const [comparisionTab, setComparisionTab] = useState<number>(0);

    // biome-ignore lint: useExhaustiveDependencies
    useEffect(() => {
        const openAuthFlow = async () => {
            await wait(100);
            if (fromVerify) {
                openAuthSlide({ showVerifyPage: true, event: AuthEvent.GENERAL_SIGNUP });
            } else if (fromReset) {
                openAuthSlide({ showResetPage: true, event: AuthEvent.GENERAL_SIGNUP });
            } else if (fromLogin) {
                openAuthSlide({ showLoginPage: true, event: AuthEvent.GENERAL_SIGNUP });
            } else if (fromSignup) {
                openAuthSlide({ showSignupPage: true, event: AuthEvent.GENERAL_SIGNUP });
            }
        };

        const error = decodeURIComponent(searchParams.get("error") ?? "");
        const errorDescription = decodeURIComponent(searchParams.get("error_description") ?? "");

        if (error) {
            if (errorDescription.includes("attributes required")) {
                toast.error(
                    "This account does not have a valid email address attached. Please fix this in your account settings on the login provider, and try again."
                );
            } else if (error.includes("invalid_request")) {
                toast.error("Invalid request. Please try again.");
            } else {
                toast.error("An error occurred. Please try again.");
            }
        }

        openAuthFlow();
    }, []);

    return (
        <LandingPageWrapper landing>
            <LogPageView page={"Smart Cards Landing Page - Visited"} />
            <LandingFullWidthSection style={{ backgroundColor: themeColors.neutralWhite, padding: "2.4rem 0" }}>
                <AIStudyToolsSection />
            </LandingFullWidthSection>

            <LandingFullWidthSection style={{ backgroundColor: themeColors.background, padding: "8rem 0 0" }}>
                <LandingHalfSection>
                    <h2 className="heading2" style={{ marginBottom: "1rem" }}>
                        Trusted by millions
                    </h2>
                    <p className="bodyXl" style={{ marginBottom: "2.5rem" }}>
                        Everyone is relying on Knowt, and we never let them down.
                    </p>
                    <FlexRowAlignCenter
                        className={br.smDownColumn}
                        style={{ gap: "2.4rem", marginBottom: "2.5rem", width: "100%" }}>
                        <StatsCards />
                    </FlexRowAlignCenter>
                    <FeedbackCard users={USER_REVIEWS[ReviewType.HOMEPAGE]} style={{ maxHeight: "50rem" }} />
                </LandingHalfSection>
            </LandingFullWidthSection>

            <LandingFullWidthSection style={{ backgroundColor: themeColors.neutralWhite, padding: "8rem 0 8rem" }}>
                <LandingHalfSection>
                    <h2 className="heading2" style={{ marginBottom: "1.6rem" }}>
                        Your all in one platform
                    </h2>
                    <p className="bodyXl" style={{ marginBottom: "4rem" }}>
                        We know how tough it is to pick the right AI tool so we wanted to make it an easy decision.
                    </p>
                    <StudentTeacherFeaturesTab />
                </LandingHalfSection>
            </LandingFullWidthSection>

            <LandingFullWidthSection style={{ backgroundColor: themeColors.background, padding: "9rem 0 9rem" }}>
                <LandingHalfSection>
                    <h2 className="heading2" style={{ marginBottom: "1.6rem" }}>
                        Comparing Knowt vs. Quizlet, and the others!
                    </h2>
                    <p className="bodyXl" style={{ marginBottom: "3.5rem" }}>
                        Click on the tool you have a contract to learn why we’re better.
                    </p>
                    <Tabs
                        containerStyle={{ marginBottom: "3.5rem" }}
                        tabs={[
                            { label: "For Students", value: "student" },
                            { label: "For Teachers", value: "teacher" },
                        ]}
                        selectedTab={comparisionTab}
                        onChange={(_tab, i) => setComparisionTab(i)}
                        tabsSx={{
                            "& .Mui-selected": { color: themeColors.pureBlack },
                            "& .MuiTabs-indicator": {
                                backgroundColor: themeColors.primary3,
                                color: themeColors.pureBlack,
                            },
                            backgroundColor: themeColors.flashcardsLight,
                        }}
                        tabSx={{
                            ":not(.Mui-selected):hover": {
                                height: "80%",
                                backgroundColor: themeColors.flashcardsMed,
                                borderRadius: 999,
                                outline: "none",
                            },
                        }}
                    />
                    <CompetitorsComparisonTable
                        primaryCompetitor={comparisionTab === 0 ? "Quizlet" : "MagicSchool"}
                        isForStudents={comparisionTab === 0}
                        hideAITools={comparisionTab === 0}
                    />
                </LandingHalfSection>
            </LandingFullWidthSection>

            <LandingFullWidthSection style={{ backgroundColor: themeColors.neutralWhite, padding: "8rem 0 8rem" }}>
                <LandingHalfSection>
                    <h2 className="heading2" style={{ marginBottom: "1.6rem" }}>
                        Why use Knowt?
                    </h2>
                    <p className="bodyXl" style={{ marginBottom: "4rem" }}>
                        We know how tough it is to pick the right AI tool so we wanted to make it an easy decision.,
                    </p>
                    <FlexRowAlignCenter
                        style={{
                            gap: spacing.MD,
                            alignItems: "stretch",
                            flexWrap: "wrap",
                            marginBottom: spacing.LG_3,
                        }}>
                        <BenefitsCards
                            benefits={[
                                {
                                    color: themeColors.videoLight,
                                    img: <Hourglass />,
                                    title: "Free Learn Mode",
                                    desc: "When you join the pilot program, you’ll receive FREE unlimited licenses for your school for 6 months. Have them try it out to make sure they actually love Knowt before taking the leap.",
                                },
                                {
                                    color: themeColors.docsLight,
                                    img: <Library />,
                                    title: "The only free Quizlet alternative you’ll need",
                                    desc: "Once your pilot ends, if you choose to stick with Knowt, you’ll automatically lock in a price that’s 50% cheaper than the other AI tools for the next few years.",
                                },
                                {
                                    color: themeColors.pdfLight,
                                    img: <School />,
                                    title: "One site to make notes, flashcards, and more",
                                    desc: "Get the functionality of Magic School, Quizlet, Quizziz, Brisk AI and SchoolAI in one tool. Less tools for you to manage -- one PO and one admin dashboard to manage everything",
                                },
                            ]}
                            style={{
                                border: `1px solid ${themeColors.neutral1}`,
                            }}
                        />
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter
                        className={br.smDownColumn}
                        style={{ gap: spacing.MD, alignItems: "stretch", width: "100%" }}>
                        <BrowseEncouragementCards
                            encouragementCards={[
                                {
                                    title: "Browse our 25+ Teacher tools",
                                    href: "/teachers/knowt-knights-teacher-ambassadors",
                                    image: `${ASSETS_URL}/images/knowt-ambassador-benefits.svg`,
                                },
                                {
                                    title: "Browse our AI Student Tools",
                                    href: "/free-ai-tools-for-teachers",
                                    image: `${ASSETS_URL}/images/homework-helper-encouragement.svg`,
                                },
                            ]}
                        />
                    </FlexRowAlignCenter>
                </LandingHalfSection>
            </LandingFullWidthSection>

            <LandingFullWidthSection style={{ backgroundColor: themeColors.background, padding: "8rem 0 8rem" }}>
                <LandingHalfSection style={{ backgroundColor: themeColors.background }}>
                    <h2 className="heading2" style={{ marginBottom: "1.6rem" }}>
                        Oh, you’re an explorer?
                    </h2>
                    <p className="bodyXl" style={{ marginBottom: "4rem", maxInlineSize: "86rem" }}>
                        We have over 2 million resources across various exams, and subjects to refer to at any point.
                    </p>
                    <ExamPicker featuredExamCategories={featuredSubjectCategories} />
                    <ExploreTopUserContents
                        flashcardSets={topFlashcardSets}
                        containerStyle={{ marginBottom: "4rem", marginTop: "4rem" }}
                    />
                    <ExploreTopUserContents notes={topNotes} />
                </LandingHalfSection>
            </LandingFullWidthSection>

            <LandingFullWidthSection style={{ backgroundColor: themeColors.neutralWhite, padding: "8rem 0 8rem" }}>
                <LandingHalfSection style={{ maxWidth: "70rem" }}>
                    <FAQ
                        title="FAQ"
                        titleClassName="heading2"
                        description="We thought you might have some questions..."
                        containerStyle={{ backgroundColor: themeColors.neutralWhite, gap: "4rem" }}
                        faqs={aiFlashcardsFAQs}
                    />
                </LandingHalfSection>
            </LandingFullWidthSection>
            <Footer landing />
        </LandingPageWrapper>
    );
};

export default UploadMainLandingPage;
