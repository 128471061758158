"use client";

import { useEffect } from "react";
import Mixpanel from "@/utils/analytics/Mixpanel";

export default function LogPageView({ page, props = {} }) {
    useEffect(() => {
        Mixpanel.track(page, props);

        // set the app argument for itunes, so that the app can be opened from the website
        // * <meta name="apple-itunes-app" content="app-id=123456789" />
        // * <meta name="apple-itunes-app" content="app-id=123456789, app-arguments=123456789" />
        const pathname = window.location.pathname;
        document.documentElement
            .querySelector('meta[name="apple-itunes-app"]')
            ?.setAttribute("content", "app-id=6463744184, app-argument=" + pathname);
    }, []);

    return null;
}
