"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { ConditionalLink } from "@/components/ConditionalLink";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Hammer, Swords } from "lucide-react";
import Image from "next/image";
import { useMemo, useState } from "react";

type Feature = {
    feature: string;
    explanation?: string;
    MagicSchool: boolean;
    Quizlet: boolean;
    Quizizz: boolean;
    SchoolAI: boolean;
    Brisk: boolean;
    isAITool: boolean;
    StudyFetch: boolean;
    Gauth: boolean;
    Mindgrasp: boolean;
    Gizmo: boolean;
};

const createFeature = (
    feature: string,
    explanation: string | undefined = undefined,
    overrides: Partial<Feature> = {}
): Feature => ({
    feature,
    explanation,
    MagicSchool: false,
    Quizlet: false,
    Quizizz: false,
    SchoolAI: false,
    Brisk: false,
    StudyFetch: false,
    Gauth: false,
    Mindgrasp: false,
    Gizmo: false,
    isAITool: false,
    ...overrides,
});

const OVERVIEW_FEATURES: Record<string, Feature> = {
    AI_SUMMURIZERS: createFeature("AI Summarizers", "Instant Practice Questions, Notes, & flashcards from any file"),
    LIVE_LECTURE: createFeature("Live AI Lecture Notetaker"),
    AI_TOOLS: createFeature("25+ AI Tools for Teachers", undefined, {
        MagicSchool: true,
        SchoolAI: true,
        Brisk: true,
        StudyFetch: true,
        Mindgrasp: true,
        Gizmo: true,
    }),
    MOBILE_APP: createFeature("Mobile Application", undefined, {
        Quizlet: true,
        Quizizz: true,
    }),
    DETAILED_AI_ANALYTICS: createFeature("Detailed AI analytics on assignments", undefined, {
        Quizizz: true,
        SchoolAI: true,
        Brisk: true,
        Gauth: true,
        Mindgrasp: true,
        Gizmo: true,
    }),
    COMPLIANCE: createFeature("FERPA, COPPA, SOPPA, EdLaw 2D Compliance", undefined, {
        MagicSchool: true,
        Quizizz: true,
        SchoolAI: true,
        Brisk: true,
        StudyFetch: true,
        Gauth: true,
        Mindgrasp: true,
        Gizmo: true,
    }),
    NO_ADS: createFeature("No Ads (inside or outside classes)", undefined, {
        MagicSchool: true,
        Quizizz: true,
        SchoolAI: true,
        Brisk: true,
        StudyFetch: true,
        Gauth: true,
        Mindgrasp: true,
        Gizmo: true,
    }),
    CREATE_SAHRE_NOTES: createFeature("Create & share notes"),
    CREATE_SHARE_FLASHCARDS: createFeature("Create & share flashcards", undefined, {
        Quizlet: true,
        Quizizz: true,
        Gauth: true,
    }),
    UNLIMITED_STUDY: createFeature("Study with unlimited free Learn, Matching, & Spaced Repetition Modes"),
    BROWSE_MATERIALS: createFeature("Browse millions of notes, flashcards, and other study materials", undefined, {
        Quizlet: true,
        Quizizz: true,
        Gauth: true,
    }),
    AP_GUIDES: createFeature("AP Study Guides and unlimited practice questions"),
    ADMIN_DASHBOARD: createFeature("Admin Dashboard Insights", undefined, {
        MagicSchool: true,
        Quizizz: true,
        SchoolAI: true,
        Brisk: true,
        StudyFetch: true,
        Gauth: true,
        Mindgrasp: true,
        Gizmo: true,
    }),
    CLEVER_CLASSLINK: createFeature("Clever & ClassLink SSO", undefined, {
        MagicSchool: true,
        Quizizz: true,
        SchoolAI: true,
        StudyFetch: true,
        Gauth: true,
        Mindgrasp: true,
    }),
    AI_PDF_TOOL: createFeature("AI PDF Summarizer", "upload a PDF and it creates notes or flashcards", {
        Quizlet: true,
        isAITool: true,
    }),
    AI_PPT_TOOL: createFeature("AI PPT Summarizer", "upload a PowerPoint and it creates notes or flashcards", {
        Quizlet: true,
        isAITool: true,
    }),
    AI_VIDEO_TOOL: createFeature("AI Video Summarizer", "upload any video and it creates notes or flashcards", {
        isAITool: true,
    }),
    AI_LIVE_LECTURE_TOOL: createFeature(
        "AI Live Lecture Note Taker",
        "record your lecture and it creates notes or flashcards",
        {
            isAITool: true,
        }
    ),
    AI_SCIENCE_LAB: createFeature("AI Science Lab Generator", undefined, {
        MagicSchool: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_TEXT_PARAPHRASER: createFeature("AI Text Paraphraser", undefined, {
        MagicSchool: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_REPORT_CARD_TOOL: createFeature("AI Report Card Comments Generator", undefined, {
        MagicSchool: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_EMAIL_TOOL: createFeature("AI Professional Email Generator", undefined, {
        MagicSchool: true,
        SchoolAI: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_WORKSHEET_TOOL: createFeature("AI Worksheet Generator", undefined, {
        MagicSchool: true,
        SchoolAI: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_EXIT_QUIZ_TOOL: createFeature("AI Exit Quiz Generator", undefined, {
        MagicSchool: true,
        Quizizz: true,
        SchoolAI: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_LESSON_PLAN_TOOL: createFeature("AI Lesson Plan Generator", undefined, {
        MagicSchool: true,
        Quizizz: true,
        SchoolAI: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_RUBRIC_TOOL: createFeature("AI Rubric Generator", undefined, {
        MagicSchool: true,
        SchoolAI: true,
        Brisk: true,
        isAITool: true,
    }),
    AI_TEXT_LEVELER_TOOL: createFeature("AI Text/File Leveler", undefined, {
        MagicSchool: true,
        SchoolAI: true,
        Brisk: true,
        isAITool: true,
    }),
};

const STUDENT_OVERVIEW_FEATURES: Record<string, Feature> = {
    CREATE_SHARE_FLASHCARDS: createFeature("Create & share flashcards", undefined, {
        Quizlet: true,
        StudyFetch: true,
        Mindgrasp: true,
        Gizmo: true,
    }),
    UNLIMITED_STUDY: createFeature("Study with unlimited free Learn, Matching, & Spaced Repetition Modes", undefined, {
        Gizmo: true,
    }),
    AI_SUMMURIZERS: createFeature("AI Summarizers", "Instant Practice Questions, Notes, & flashcards from any file", {
        Quizlet: true,
        StudyFetch: true,
    }),
    LIVE_LECTURE: createFeature("Live AI Lecture Notetaker", undefined, {
        StudyFetch: true,
        Mindgrasp: true,
    }),
    SNAP_SOLVE: createFeature("Snap & Solve any problems using AI", undefined, {
        Gauth: true,
    }),
    CREATE_SAHRE_NOTES: createFeature("Create & share notes"),
    BROWSE_MATERIALS: createFeature("Browse millions of notes, flashcards, and other study materials", undefined, {
        Quizlet: true,
    }),
    AP_GUIDES: createFeature("AP Study Guides and unlimited practice questions"),
    ATTACH_IMAGES: createFeature("Attaching images to term sides"),
    FLASHCARDS_FORMATTING: createFeature("Free flashcards formatting"),
};

const CheckMark = ({ isPrimary }) => {
    return (
        // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
            <rect
                x="0.5"
                width="34"
                height="34"
                rx="17"
                fill={`${isPrimary ? themeColors.primary : themeColors.neutralBlack}`}
            />
            <path
                d="M23.4728 12.8647L15.26 21.135L11.5269 17.3758"
                stroke={isPrimary ? "#15504A" : themeColors.neutralWhite}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const HEADERS = {
    MagicSchool: ["MagicSchool", "Quizlet", "Quizizz", "SchoolAI", "Brisk"],
    SchoolAI: ["SchoolAI", "MagicSchool", "Quizlet", "Quizizz", "Brisk"],
    Quizizz: ["Quizizz", "SchoolAI", "MagicSchool", "Quizlet", "Brisk"],
    Quizlet: ["Quizlet", "Quizizz", "SchoolAI", "MagicSchool", "Brisk"],
    Brisk: ["Brisk", "Quizizz", "Quizlet", "SchoolAI", "MagicSchool"],
};

const HEADERS_FOR_STUDENTS = ["Quizlet", "StudyFetch", "Gauth", "Mindgrasp", "Gizmo"];

const COMPETITOR_TO_LINK_MAP = {
    MagicSchool: "/teachers/free-magic-school-alternative",
    SchoolAI: "/teachers/free-schoolai-alternative",
    Quizizz: "/teachers/free-quizizz-alternative",
    Quizlet: "/teachers/free-quizlet-alternative",
    Brisk: "/teachers/free-brisk-teaching-alternative",
};

const CompetitorsComparisonTable = ({
    primaryCompetitor,
    hideAITools = false,
    isForStudents = false,
}: {
    primaryCompetitor: "MagicSchool" | "SchoolAI" | "Quizizz" | "Quizlet" | "Brisk";
    hideAITools?: boolean;
    isForStudents?: boolean;
}) => {
    const [showingOverview, setShowingOverview] = useState<boolean>(true);

    const featuresToShow = useMemo(
        () =>
            isForStudents
                ? Object.values(STUDENT_OVERVIEW_FEATURES)
                : showingOverview
                  ? Object.values(OVERVIEW_FEATURES).filter(feature => !feature.isAITool)
                  : Object.values(OVERVIEW_FEATURES).filter(feature => feature.isAITool),
        [showingOverview, isForStudents]
    );

    const headers = isForStudents ? HEADERS_FOR_STUDENTS : HEADERS[primaryCompetitor];

    return (
        <div
            style={{
                padding: `${spacing.SM} 0`,
                borderRadius: borderRadius.card,
                backgroundColor: themeColors.neutralWhite,
                width: "calc(95dvw - 2rem)",
                maxWidth: "100%",
                gap: spacing.SM,
            }}>
            <FlexRowAlignCenter
                style={{ paddingLeft: spacing.SM, gap: spacing.SM, marginBottom: "2rem", flexWrap: "wrap" }}>
                <CircularRectTextButton
                    onClick={() => setShowingOverview(true)}
                    style={{
                        gap: spacing.XS,
                        padding: `1rem ${spacing.SM}`,
                        backgroundColor: showingOverview ? themeColors.neutralBlack : themeColors.neutralWhite,
                        color: showingOverview ? themeColors.neutralWhite : themeColors.neutralBlack,
                        border: showingOverview ? "none" : `1px solid ${themeColors.neutral1}`,
                    }}>
                    <Swords size={24} color={showingOverview ? themeColors.neutralWhite : themeColors.neutralBlack} />
                    {"Overview"}
                </CircularRectTextButton>
                {!hideAITools && (
                    <CircularRectTextButton
                        onClick={() => setShowingOverview(false)}
                        style={{
                            gap: spacing.XS,
                            padding: `1rem ${spacing.SM}`,
                            backgroundColor: !showingOverview ? themeColors.neutralBlack : themeColors.neutralWhite,
                            color: !showingOverview ? themeColors.neutralWhite : themeColors.neutralBlack,
                            border: !showingOverview ? "none" : `1px solid ${themeColors.neutral1}`,
                        }}>
                        <Hammer
                            size={24}
                            color={!showingOverview ? themeColors.neutralWhite : themeColors.neutralBlack}
                        />
                        {"Popular AI Tools"}
                    </CircularRectTextButton>
                )}
            </FlexRowAlignCenter>
            <FlexColumn style={{ maxWidth: "100%", overflowX: "scroll" }}>
                <table
                    style={{
                        borderCollapse: "collapse",
                        borderSpacing: "0",
                        width: "100%",
                    }}>
                    <thead>
                        <tr style={{ verticalAlign: "middle" }}>
                            <th
                                className="secondaryTextBold1"
                                style={{
                                    textAlign: "left",
                                    verticalAlign: "center",
                                    width: "35.7rem",
                                    padding: "2.4rem 2.4rem 2.4rem 1.5rem",
                                    borderBottom: `1px solid ${themeColors.neutral1}`,
                                }}>
                                Features
                            </th>
                            <th
                                className="secondaryTextBold1"
                                style={{
                                    verticalAlign: "center",
                                    width: "12.5rem",
                                    backgroundColor: themeColors.neutralBlack,
                                    padding: "1.5rem 1rem 1rem",
                                    borderRadius: "2rem 2rem 0 0",
                                    color: themeColors.neutralWhite,
                                }}>
                                <Image src={`${ASSETS_URL}/images/Knowt-logo.svg`} alt="Knowt" width={80} height={30} />
                            </th>
                            {headers.map((header: string) => (
                                <th
                                    key={header}
                                    className="secondaryTextBold1"
                                    style={{
                                        textAlign: "center",
                                        width: "12.5rem",
                                        padding: "1rem",
                                        borderBottom: `1px solid ${themeColors.neutral1}`,
                                    }}>
                                    <ConditionalLink
                                        condition={!!COMPETITOR_TO_LINK_MAP[header]}
                                        href={COMPETITOR_TO_LINK_MAP[header]}
                                        style={{
                                            display: "flex",
                                            height: "5rem",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}>
                                        <FlexColumnAlignJustifyCenter style={{ width: "100%" }}>
                                            <Image
                                                src={`${ASSETS_URL}/images/${header}-Logo.png`}
                                                alt={header}
                                                width={90}
                                                height={40}
                                                style={{
                                                    height: "50%",
                                                }}
                                            />
                                        </FlexColumnAlignJustifyCenter>
                                    </ConditionalLink>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {featuresToShow.map((feature, index) => (
                            <tr key={feature.feature} style={{ height: "100%" }}>
                                <td
                                    style={{
                                        padding: "2.4rem 2.4rem 2.4rem 1.5rem",
                                        borderBottom:
                                            index === featuresToShow.length - 1
                                                ? "none"
                                                : `1px solid ${themeColors.neutral1}`,
                                    }}>
                                    <span className="secondaryTextBold1">{feature.feature}</span>
                                    {feature.explanation && (
                                        <span className="secondaryText1"> ({feature.explanation})</span>
                                    )}
                                </td>
                                <td
                                    style={{
                                        padding: "2.4rem 4rem",
                                        backgroundColor: themeColors.neutralBlack,
                                        borderRadius: index === featuresToShow.length - 1 ? "0 0 2rem 2rem" : "unset",
                                        height: "100%",
                                    }}>
                                    <CheckMark isPrimary={true} />
                                </td>
                                {headers.map(headerTool => (
                                    <td
                                        key={`${feature.feature}_${headerTool}`}
                                        style={{
                                            padding: "2.4rem 4rem",
                                            height: "100%",
                                            borderBottom:
                                                index === featuresToShow.length - 1
                                                    ? "none"
                                                    : `1px solid ${themeColors.neutral1}`,
                                        }}>
                                        {feature[headerTool] ? <CheckMark isPrimary={false} /> : null}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FlexColumn>
        </div>
    );
};

export default CompetitorsComparisonTable;
