/*
  Replacer function to JSON.stringify that ignores
  circular references and internal React properties.
  https://github.com/facebook/react/issues/8669#issuecomment-531515508
*/
const ignoreCircularReferences = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
        if (key.startsWith("_")) return; // Don't compare React's internal props.
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) return;
            seen.add(value);
        }
        return value;
    };
};

/**
 * convert `ReactNode` to string so that a `children` can be used as a key for another component
 * pass `seed` if you want to make it even more unique. `key={ childrenAsKey({ children, seed: index }) }`
 */
export const childrenAsKey = ({ children, seed }: { children: React.ReactNode; seed?: string | number }) => {
    const key = JSON.stringify(children, ignoreCircularReferences());
    return key + seed;
};
